import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Link from 'gatsby-link'

import logo from '../images/design/logo-goshen.png'
//import logo_cnef from '../images/design/logo-cnef.jpg'
import logo_fece from '../images/design/logo-fece.jpg'

const themeColor = (pathname) => {
  if (pathname === '/')
    return 'twlsky-red twlsky-mixedcolours'
  else if (pathname === '/about/')
    return 'twlsky-orange twlsky-mixedcolours'
  else if (pathname === '/index-news/' || pathname.startsWith('/news/'))
    return 'twlsky-yellow twlsky-mixedcolours'  
  else if (pathname === '/index-blog/' || pathname.startsWith('/blog/'))
    return 'twlsky-green twlsky-mixedcolours'
  return 'twlsky-blue twlsky-mixedcolours'
}

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title,
            version
          }
        }
      }
    `}
    render={data => (
      <div className={themeColor(location.pathname)}>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' },
          ]}
        >
          <html lang="fr" />
        </Helmet>

        {/* Page Outer Container */}
        <div className="outer-container clearfix">

          {/* =========== Header =========== */}
          <header className="clearfix">

            <div className="logo">
              <a href="/"><img src={logo} alt="Église Goshen" /></a>
            </div>

            <div id="main-menu-container" className="clearfix">

              <div id="mobile-menu-icon" className="btn btn-outline-inverse visible-xs"><span className="glyphicon glyphicon-th"></span></div>

              <ul id="main-menu">
                <li className={"menu-item " + (location.pathname === '/' ? 'current-menu-ancestor' : '')}><Link to="/">Accueil</Link></li>
                <li className={"menu-item " + (location.pathname === '/about/' ? 'current-menu-ancestor' : '')}><Link to="/about/">À propos</Link></li>
                <li className={"menu-item " + (location.pathname === '/index-news/' || location.pathname.startsWith('/news/') ? 'current-menu-ancestor' : '')}><Link to="/index-news/">Actualités</Link></li>
                <li className={"menu-item " + (location.pathname === '/index-blog/' || location.pathname.startsWith('/blog/') ? 'current-menu-ancestor' : '')}><Link to="/index-blog/">Blog</Link></li>
                <li className={"menu-item " + (location.pathname === '/contact/' ? 'current-menu-ancestor' : '')}><Link to="/contact/">Contact</Link></li>
              </ul>{/* #main-menu */}

            </div>{/* #menu-container */}

          </header>
          {/* End: Header */}

          <div className="inside-body-content-container clearfix">
            {children}
          </div>

          {/* =========== Footer Alt =========== */}
          <footer className="container-fluid horizontal-section-container clearfix">
            <div className="row">

              {/* footer hor divider */}
              <div className="col-xs-12"><hr className="hor-divider" /></div>

              <div className="col-sm-4">

                <div id="footer-affiliates" className="section-container clearfix">
                  {/* eslint-disable-next-line */}
                  <a href="#" className="section-header footer-header">
                    <h5 className="text">Affiliation</h5>
                  </a>

                  <div className="section-content clearfix">

                    <ul id="affiliates-slider-4cols" className="icons-grid-list-4cols owl-carousel owl-theme">

                      {/*<li className="icon clearfix">
                        <a target="_blank" href="http://lecnef.org/" rel="noopener noreferrer"><img src={logo_cnef} alt="CNEF" /></a>
                      </li>*/}
                      <li className="icon clearfix">
                        <a target="_blank" href="http://fede-eglises.com/" rel="noopener noreferrer"><img src={logo_fece} alt="FECE" /></a>
                      </li>

                    </ul>
                  </div>

                </div>
              </div>

              <div className="col-sm-8">
                {/* Contact Us */}
                <div id="footer-contact-us" className="section-container clearfix">

                  <Link to="/contact/" className="section-header footer-header">
                    <h5 className="text">Nous Contacter</h5>
                  </Link>

                  <div className="section-content clearfix">
                    <div className="vertical-simple-list contact-list">

                      <div className="col-xs-6">
                        <div className="item clearfix"><div className="item-content">
                          <div className="icon"><span className="glyphicon glyphicon-map-marker"></span></div>
                          <h6 className="title">Galerie commerciale Renoir<br />17 Avenue Auguste Renoir<br />06800 Cagnes-sur-Mer</h6>
                        </div></div>
                      </div>

                      <div className="col-xs-6">
                        <div className="item clearfix"><div className="item-content">
                          <div className="icon"><span className="glyphicon glyphicon-earphone"></span></div>
                          <h6 className="title">04 93 73 10 58</h6>
                        </div></div>

                        <div className="item clearfix"><div className="item-content">
                          <div className="icon"><span className="glyphicon glyphicon-envelope"></span></div>
                          {/* eslint-disable-next-line */}
                          <h6 className="title"><a className="email-link" href="#"><span className="backwards">moc.liamg@nehsog.esilge</span></a></h6>
                        </div></div>
                      </div>

                    </div>
                  </div>

                </div>
                {/* End: Contact Us */}
              </div>

            </div>
          </footer>
          {/* End: Footer Alt */}

        </div>{/* .outer-container */}
        {/* End: Page Outer Container */}

        {/* Beneath Footer */}
        <div id="beneath-footer" className="center-container clearfix">

          {/* left */}
          <div className="col-sm-6 beneath-footer-left">
            {/* eslint-disable-next-line */}
            <div className="text">v{data.site.siteMetadata.version} &copy; 2018-{new Date().getFullYear()} {data.site.siteMetadata.title} /// odelia technologies</div>
          </div>

          {/* right */}
          <div className="col-sm-6 beneath-footer-right">
            <ul className="social-media-icons clearfix">
              <li><a rel="noopener noreferrer" href="https://www.facebook.com/eglisegoshen/" target="_blank" title="Facebook" aria-label="Facebook"><i className="fa fa-facebook"></i></a></li>
            </ul>
          </div>

        </div>{/* #beneath-footer */}

        {/* go to top */}
        <div id="go-to-top" title="Go to top"><span className="glyphicon glyphicon-chevron-up"></span></div>

      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired
}

export default Layout
